import { MouseEvent, useState } from 'react';
import { GETITNOW,CAREEROPPORTUNITIES,ENTERPRISEURL} from '../constant';


const GetItNow = () => {
   // PS_03: GIN initializes state with activeTab = GETITNOW
  const [activeTab, setActiveTab] = useState<string>(GETITNOW);

  // PS_07, PS_13: GIN handles tab click with handleTabClick(event)
  const handleTabClick = (event: MouseEvent<HTMLButtonElement>) => {
       // PS_08, PS_14: GIN sets activeTab with event.currentTarget.id
      setActiveTab(event.currentTarget.id);
  };
// PS_04, PS_09, PS_10, PS_15, PS_16: GIN sets iframeUrl conditionally based on activeTab
  const iframeUrl = activeTab === GETITNOW ? ENTERPRISEURL.GETITNOW : ENTERPRISEURL.CAREEROPPORTUNITIESGETITNOW;

  return (
    <div>
      <div>
        {/* header starts here */}
        <nav className="navbar navbar-expand-md topnav-z-index kiosk-nav sticky-top navbar-light bg-white py-3">
          <div className="container-fluid">
            <div className="d-flex align-items-center gap-4">
              <div className="navbar-brand ms-2" ><img src="images/get-it-now-logo.svg" alt="brand-logo" className="brand-logo" /></div>
              <ul className="nav nav-pills tab-parent" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className={`nav-link font-14 font-semibold cust-tab  position-relative ${iframeUrl === ENTERPRISEURL.GETITNOW ? 'active' : ''}`} data-bs-toggle="pill" data-bs-target="#rentacenterTab"
                  id={GETITNOW}
                  onClick={handleTabClick}
                   type="button" role="tab" aria-selected="true">GETITNOWSTORES.COM</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className={`nav-link font-14 font-semibold cust-tab position-relative ${iframeUrl === ENTERPRISEURL.CAREEROPPORTUNITIESGETITNOW ? 'active' : ''}`}
                  id={CAREEROPPORTUNITIES}
                  onClick={handleTabClick} 
                  data-bs-toggle="pill" data-bs-target="#careerOppTab" type="button" role="tab" aria-selected="false">CAREER
                    OPPORTUNITIES</button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
         {/* IFrame */}
         <iframe src={iframeUrl} style={{ width: '100%', height: '80vh', border: 'none' }} title="GetItNow"/>
      </div>

    </div>
  )
}

export default GetItNow