import  { MouseEvent, useState } from 'react';
import { HOMECHOICE,CAREEROPPORTUNITIES,ENTERPRISEURL } from '../constant';

const HomeChoice = () => {
    // PS_03: HC initializes state with activeTab = HOMECHOICE
  const [activeTab, setActiveTab] = useState<string>(HOMECHOICE);
  // PS_06, PS_12: handleTabClick event handler
  const handleTabClick = (event: MouseEvent<HTMLButtonElement>) => {
      setActiveTab(event.currentTarget.id);
  };
   // PS_05, PS_11, PS_17:  Dynamically compute iframeUrl based on activeTab using ternary operator
  const iframeUrl = activeTab === HOMECHOICE ? ENTERPRISEURL.HOMECHOICE : ENTERPRISEURL.CAREEROPPORTUNITIESHOMECHOICE;

  return (
    <div>
      <div>
        <nav className="navbar navbar-expand-md topnav-z-index kiosk-nav sticky-top navbar-light bg-white py-3">
          <div className="container-fluid">
            <div className="d-flex align-items-center gap-4">
              <div className="navbar-brand ms-2"><img src="images/home-choice-logo.svg" alt="brand-logo" className="brand-logo" /></div>
              <ul className="nav nav-pills tab-parent" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className={`nav-link font-14 font-semibold cust-tab position-relative ${iframeUrl === ENTERPRISEURL.HOMECHOICE ? 'active' : ''}`}
                   data-bs-toggle="pill"
                   data-bs-target="#rentacenterTab"
                    id ={HOMECHOICE}
                    onClick={handleTabClick}
                    type="button" role="tab" 
                    aria-selected="true"
                    >HOMECHOICESTORES.COM</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={`nav-link font-14 font-semibold cust-tab position-relative ${iframeUrl === ENTERPRISEURL.CAREEROPPORTUNITIESHOMECHOICE ? 'active' : ''}`}
                    data-bs-toggle="pill"
                    data-bs-target="#careerOppTab"
                    type="button"
                    role="tab"
                    aria-selected="false"
                    id={CAREEROPPORTUNITIES}
                    onClick={handleTabClick}
                  >CAREER OPPORTUNITIES
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
         {/* IFrame */}
         <iframe src={iframeUrl} style={{ width: '100%', height: '82vh', border: 'none' }} title="HomeChoice"/>
      </div>

    </div>
  )
}

export default HomeChoice