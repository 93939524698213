import { MouseEvent, useState } from 'react';
import { RENTACENTER,CAREEROPPORTUNITIES,ENTERPRISEURL } from '../constant';

const RentACenter = () => {
   // PS_03  State for the active tab
  const [activeTab, setActiveTab] = useState<string>(RENTACENTER);
 // PS_07, PS_14, PS_08, PS_14 iframeUrl is updated based on the ternary operation above when activeTab changes
  const handleTabClick = (event: MouseEvent<HTMLButtonElement>) => {
    setActiveTab(event.currentTarget.id);
};
   //  PS_09, PS_10, PS_15, PS_16 Uses the `activeTab` state to determine the `iframeUrl` consistently.
  const iframeUrl = activeTab === RENTACENTER
      ? ENTERPRISEURL.RENTACENTER
      : ENTERPRISEURL.CAREEROPPORTUNITIESRENTACENTER;

  return (
    <div>
      <div>
        {/* header starts here */}
        <nav className="navbar navbar-expand-md topnav-z-index kiosk-nav sticky-top navbar-light bg-white py-3">
          <div className="container-fluid">
            <div className="d-flex align-items-center gap-4">
              <div className="navbar-brand ms-2" ><img src="images/rac-logo.svg" alt="brand-logo" className="brand-logo" /></div>
              <ul className="nav nav-pills tab-parent" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className={`nav-link font-14 font-semibold cust-tab position-relative ${iframeUrl === ENTERPRISEURL.RENTACENTER ? 'active' : ''}`} 
                  data-bs-toggle="pill" data-bs-target="#rentacenterTab" 
                  type="button" role="tab" 
                   id = {RENTACENTER}
                   onClick={handleTabClick}>RENTACENTER.COM</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className={`nav-link font-14 font-semibold cust-tab position-relative ${iframeUrl === ENTERPRISEURL.CAREEROPPORTUNITIESRENTACENTER ? 'active' : ''}`}
                   data-bs-toggle="pill" data-bs-target="#careerOppTab"
                    type="button" role="tab" 
                  id={CAREEROPPORTUNITIES}
                  onClick={handleTabClick}>CAREER
                    OPPORTUNITIES</button>
                </li>
              </ul>
            </div>
          </div>
        </nav>
         {/* IFrame */}
         <iframe src={iframeUrl} style={{ width: '100%', height: '76vh', border: 'none' }} title="RentACenter"/>
      </div>
    </div>
  )
}

export default RentACenter