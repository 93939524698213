export const RENTACENTER= 'RENTACENTER';
export const GETITNOW= 'GETITNOW';
export const HOMECHOICE= 'HOMECHOICE';
export const CAREEROPPORTUNITIES= 'CAREEROPPORTUNITIES';


export const ENTERPRISEURL= {
    RENTACENTER:'https://www.rentacenter.com/',
    GETITNOW: 'https://www.getitnowstores.com/',
    HOMECHOICE:'https://www.homechoicestores.com/',
    CAREEROPPORTUNITIESRENTACENTER:'https://raccareers.com/rent-a-center',
    CAREEROPPORTUNITIESGETITNOW:' https://raccareers.com/get-it-now',
    CAREEROPPORTUNITIESHOMECHOICE:'https://raccareers.com/home-choice'
}